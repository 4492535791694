// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBV2jAU9EniAl8RQh9JuwWZ6j1KBH5jqMg",
  authDomain: "oilandenergyseo6.firebaseapp.com",
  projectId: "oilandenergyseo6",
  storageBucket: "oilandenergyseo6.appspot.com",
  messagingSenderId: "751565972329",
  appId: "1:751565972329:web:bed33d4d7f992a77b013f8"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;