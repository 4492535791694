import React, { useEffect, useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";
import auth from "../firebase.init";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
    } catch (error) {
      console.error("Error sending password reset email", error);
    }
  };

  return (
    <>
      <div className="bg-gradient2 min-vh-100 align-items-center d-flex justify-content-center pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <div className="card">
                <div className="card-body p-0">
                  <div className="p-xl-5 p-3">
                    
                    <h6 className="h5 mb-0 mt-3">Reset Password</h6>
                    <p className="text-muted mt-1 mb-4">
                      Enter your email address, and we'll send you an email with
                      instructions to reset your password.
                    </p>

                    <div className="authentication-form">
                      {resetSent ? (
                        <div className="text-center mb-4">
                          <h5>Password reset. Check your inbox!</h5>
                        </div>
                      ) : (
                        <form onSubmit={handleResetPassword}>
                          <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                              Email <small>*</small>
                            </label>
                            <input
                              type="email"
                              className="form-control mb-30"
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>

                          <div className="text-center">
                            <div className="mb-0 text-center pt-3 d-grid">
                              <button className="btn btn-primary" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>{" "}
              </div>
              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    Back to{" "}
                    <Link to="/login" className="text-primary fw-semibold ms-1">
                      Log In
                    </Link>
                  </p>
                </div>{" "}
              </div>
            </div>{" "}
          </div>
        </div>

      </div>
    </>
  );
};

export default ResetPassword;
